import { NFT_CONTRACT, NFT_MARKETPLACE_CONTRACT } from "./contracts";

// all chain's default nfts will go to this default account please change it from  NFT_ADDRESS: "0x2aC2c3e348Fb0600b93e79c61EeC457E48964d3B",
export const ChainsInfo = {
  5: {
    NETWORK_NAME: "Ethereum",
    CHAIN_NAME: "ethereum",
    CURRENCY_SYMBOL: "ETH",
    NFT_MARKETPLACE_ADDRESS: "0xB7C470715697D20FE56c6a651294E3aaC67FF9d8",
    // NFT_ADDRESS: "0x36c63b29B25518e234b5E17ad67C6b65d98371BE",
    NFT_ADDRESS: "0x8E7bdcF106311dbc52164bD9263b103141AD19aF", //set by harsh
    IS_MAIN_NET: true,
    CHAIN_ID: "5",
    BLOCK_EXPLORER_URL: "https://goerli.etherscan.io/address/",
    NATIVE_TOKEN_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    WITHDRAWAL_CONTRACT_ADDRESS: "0x75f62E870f79BB13d27a800ECC4DF0Cb7F67CD6f",
    LOGO_URL: "assets/Vector.png",
    RPC_PROVIDER_URL:
      "https://goerli.infura.io/v3/9c48d1f781404552b1a017d597f6bee1",
  },
  // 80001: {
  //   NETWORK_NAME: "Polygon",
  //   CHAIN_NAME: "polygon",
  //   CURRENCY_SYMBOL: "MATIC",
  //   LAZY_MINT: "ETH",
  //   CHAIN_HEX: "0X13881",
  //   OVE_COIN: "0xFaF5a555C53Df0D4A933F6befe1be46C6307328c",
  //   NFT_MARKETPLACE_ADDRESS: "0xc407b583845f7b2538DDDAf64477788B6FD02b7E",
  //   WITHDRAWAL_CONTRACT_ADDRESS: "0x75f62E870f79BB13d27a800ECC4DF0Cb7F67CD6f",
  //   NFT_ADDRESS: "0x3015460932Cc445bD226E439Ce82282304F2c58F", // "0x8a4cb97448C8f3679e6F41cDDF6861035562cceB",
  //   // NFT_ADDRESS: "0x2aC2c3e348Fb0600b93e79c61EeC457E48964d3B",
  //   IS_MAIN_NET: false,
  //   BUSD_ADDRESS: "0x5D77c4391593D8935D58cb2Bd84D2CFB5B5735A7",
  //   NATIVE_TOKEN_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  //   LOGO_URL: "assets/Vector.png",
  //   CHAIN_ID: "80001",
  //   BLOCK_EXPLORER_URL: "https://mumbai.polygonscan.com/address/",
  //   RPC_PROVIDER_URL: "https://rpc-mumbai.matic.today",
  // },
  97: {
    NETWORK_NAME: "BINANCE",
    CHAIN_NAME: "BINANCE",
    CURRENCY_SYMBOL: "BUSD",
    LAZY_MINT: "BNB",
    NFT_MARKETPLACE_ADDRESS: "0x921983FeCa29C78a8D984340687633ceF4Eb4Ce1",
    WITHDRAWAL_CONTRACT_ADDRESS: "0x75f62E870f79BB13d27a800ECC4DF0Cb7F67CD6f",
    // NFT_ADDRESS: "0xBeb11feBaC17D25EcA6Da467029dA3Bf0532cf76",
    NFT_ADDRESS: "0x2aC2c3e348Fb0600b93e79c61EeC457E48964d3B",
    BUSD_ADDRESS: "0x7eD262B3552f58E12Edb98BFF8aEE7C3D040dcC6",
    NATIVE_TOKEN_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    IS_MAIN_NET: false,
    LOGO_URL:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVfxo9k8fH7b2cMFkY29jdooR9x6XSFEhkdKlBUF0&s",
    CHAIN_ID: "97",
    BLOCK_EXPLORER_URL: "https://goerli.etherscan.io/",
    RPC_PROVIDER_URL:
      "https://goerli.infura.io/v3/9c48d1f781404552b1a017d597f6bee1",
  },
  56: {
    NETWORK_NAME: "BINANCE",
    CHAIN_NAME: "BINANCE",
    CURRENCY_SYMBOL: "BNB",
    OVE_COIN: "0x0d5556E58862A21db65B4Aa180da231cfE6140fE",
    NFT_MARKETPLACE_ADDRESS: "0x78A6189C59C2884f6AdF87D9A9A192c91fB85875",
    WITHDRAWAL_CONTRACT_ADDRESS: "0x03b21Bc078d5Dd6d48E6471683C478218b7F3889",
    // NFT_ADDRESS: "0x7A4f6488264c9707AC1a5bc41E23d0B77BDd79F0",
    NFT_ADDRESS: "0xA0cE5B679e0FD22391963436eb92eF68d3fAbA28",
    IS_MAIN_NET: true,
    BUSD_ADDRESS: "0x5D77c4391593D8935D58cb2Bd84D2CFB5B5735A7",
    NATIVE_TOKEN_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    LOGO_URL: "assets/binance.svg",
    CHAIN_ID: "56",
    BLOCK_EXPLORER_URL: "https://bscscan.com/address/",
    RPC_PROVIDER_URL:
      "https://goerli.infura.io/v3/9c48d1f781404552b1a017d597f6bee1",
  },
  1: {
    NETWORK_NAME: "Ethereum",
    CHAIN_NAME: "Ethereum",
    CURRENCY_SYMBOL: "ETH",
    OVE_COIN: "0x0f02C9fe405C0Da382FA4c582741A6826552a79A",
    NFT_MARKETPLACE_ADDRESS: "0x31F56a4AEF73F9211FeE8BC2284F3AD697799B08",
    WITHDRAWAL_CONTRACT_ADDRESS: "0xe2f569D3e5EbB5674A4f4cC823BD241892f8b71E",
    NFT_ADDRESS: "0x3AB69671669dbfe787196615e0896743a9740869",
    IS_MAIN_NET: true,
    NATIVE_TOKEN_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    LOGO_URL: "assets/Vector.png",
    CHAIN_ID: "1",
    BLOCK_EXPLORER_URL: "https://etherscan.io/address/",
    RPC_PROVIDER_URL: "https://eth.llamarpc.com",
  },
};
