import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
export default function TermsConditions() {
  const { t } = useTranslation();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="max-w-[1200px] mx-auto m-2">
      <h1 className="text-3xl font-bold pt-2 text-center m-3">
        楽市楽座利用規約
      </h1>

      <h5>第 1 条 （目的）</h5>
      <p className="ms-4">
        1.
        この利用規約（以下「本規約」といいます。）は、アクア株式会社（以下「当社」
        といいます。）が運営する NFT
        マーケットプレイス「楽市楽座」（以下「本サービ
        ス」といいます。）を利用する利用者（第 2
        条で定義します。）に適用されます。
        利用者は、本規約に同意の上、本サービスを利用するものとします。
      </p>
      <p className="ms-4">
        2.
        本規約は、本サービスの利用条件を定めています。本サービスに利用者登録した利
        用者は全て本規約に従い、年齢や利用環境等の条件に応じて、本規約の定める条件
        に従って本サービスを利用できるものとします。
      </p>
      <p className="ms-4">
        3. 利用者が本規約に同意することにより、当社との間に本契約（第 2
        条で定義しま す。）が成立します。
      </p>

      <h5>第 2 条 （定義）</h5>
      <p className="ms-4">
        本規約において使用する以下の用語は、以下の各号に定める意味を有します。
      </p>
      <ul className="ms-7">
        <li>
          (1)
          「本契約」：本規約を契約条件として当社及び利用者との間で締結される、本
          サービスの利用契約を指します。
        </li>
        <li>
          (2) 「対象データ」：本サービスにおいて NFT
          として記録・発行することができ、
          かつ利用者が保有権・使用権等を有するデータ（暗号資産であるものを除きま
          す。）を指します。なお、本サービス上で利用できる対象データの規格等は別
          途当社が指定するものとし、イラスト、動画、音楽、３D モデル等のデータが
          含まれますがこれらに限らないものとします。
        </li>
        <li>
          (3)
          「NFT」：対象データの一部又は全部を、ブロックチェーン上で記録・発行し
          たもののうち固有の情報が割り当てられた代替可能性のないデジタルトークン
          （Non-Fungible-Token）を指します。
        </li>{" "}
        <li>(4) 「利用者」：本サービスを利用する全ての方を指します。</li>{" "}
        <li>(5) 「出品者」：本サービス上で NFT を出品する利用者を指します。</li>{" "}
        <li>
          (6) 「一次流通出品者」：本サービス上において、第 10
          条第１項の規定に基づき NFT を作成及び出品した利用者を指します。
        </li>{" "}
        <li>
          (7) 「二次流通出品者」：本サービス上において、第 10
          条第１項の規定に基づき本 サービス内で購入した NFT
          を出品した利用者を指します。
        </li>
        <li>(8) 「購入者」：本サービス上で NFT を購入する利用者を指します。</li>
        <li>
          (9)
          「通信機器」：スマートフォン、タブレット端末又はコンピューター機器を指
          します。
        </li>
        <li>
          (10)「外部 NFT
          マーケットプレイス」：OpenSea（https://opensea.io/ja）、
          Rarible（https://rarible.com/）その他当社が別途指定する NFT
          マーケットプレ 2 イスを指します。
        </li>
      </ul>

      <h5>第 3 条 （本規約の変更）</h5>
      <p className="ms-4">
        1. 当社は、以下の各号のいずれかに該当する場合は、民法第 548 条の 4
        の規定に基づ
        き本規約を随時変更できるものとします。本規約が変更された後の本契約は、変更後
        の本規約が適用されるものとします。
      </p>
      <ul className="ms-7">
        <li>(1) 本規約の変更が、利用者の一般の利益に適合するとき</li>
        <li>
          (2)
          本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容
          の相当性及びその内容その他の変更に係る事情に照らして合理的なものである
          とき
        </li>
      </ul>
      <p className="ms-4">
        2.
        当社は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発
        生時期の 2
        週間前までに、変更後の本規約の内容及び効力発生時期を利用者に通知、
        本サービスのウェブサイト上への表示、その他当社指定の方法により利用者に周知
        するものとします。
      </p>
      <p className="ms-4">
        3.
        前二項の規定にかかわらず、前項の本規約の変更の周知後に利用者が本サービスを
        利用した場合又は当社指定の期間内に利用者が解約の手続をとらなかった場合、当
        該利用者は本規約の変更に同意したものとします。
      </p>

      <h5>第 4 条 （個人情報の取り扱い）</h5>
      <p className="ms-4">
        本サービスにおける個人情報の取り扱いに関しては、当社が別途定める「プライバシー
        ポリシー」【https://nftrakuichirakuza.io/privacypolicy】に基づき取り扱うものとしま
        す。
      </p>

      <h5>第 5 条 （本サービスの内容）</h5>
      <p className="ms-4">
        利用者は、本サービス上で以下の機能を利用することができます。
      </p>
      <ul className="ms-7">
        <li>(1) 当社指定の方法により NFT の作成及び出品を行う機能</li>
        <li>(2) 利用者間の NFT 売買に関するプラットフォーム機能</li>
        <li>(3) その他前各号に付随する機能</li>
      </ul>

      <h5>第 6 条 （利用者登録等）</h5>
      <ul className="ms-4">
        <li>
          1.
          利用者は、本規約に同意の上当社が定める手続きにより、当社が指定する利用者の
          情報（以下「利用者情報」といいます。）を入力し、本サービスのアカウント登録
          を行うものとします。
        </li>
        <li>
          2.
          利用者は、自己の利用者情報につき全て真正かつ正確な情報を登録するものとし、
          前項に基づき登録した利用者情報に変更が発生した場合、直ちに、利用者情報の変
          更手続を行う義務を負うものとします。
        </li>
        <li>
          3. 当社は、当社の裁量により、利用者登録を拒否する場合があります。
        </li>
        <li>
          4.
          利用者は、本サービス上のアカウントを第三者に対して利用、貸与、譲渡、売買又
          は質入等をすることはできないものとします。
        </li>
        <li>
          5.
          利用者は、本サービスに登録した内容が、第三者により不正に利用されないため
          に、利用者自身の責任において管理しなければならないものとします。当社は、第
          1
          項に基づき登録した情報が第三者によって使用されたことによって利用者に生じ
          た損害について一切の責任を負いません。
        </li>
        <li>
          6. 当社は、第 1
          項に基づいてアカウント登録された情報に基づいて本サービスの利用
          があった場合、当該アカウントにより識別された本人が本サービスを利用したもの
          と扱います。当該利用により生じた結果については、当該利用の原因となったアカ
          ウントにより識別される本人がその責任を負うものとします。
        </li>
        <li>
          7. 本サービスの利用に当たり、外部 NFT
          マーケットプレイス並びに当社が指定した
          スマートコントラクトシステム、デジタルアセット、及び暗号通貨の保管機能（以
          下総称して「外部サービス」といいます。）の利用が必要となる場合がございま
          す。なお、外部サービスは、当社以外の事業者により提供されるため、利用者は外
          部サービスにかかる利用規約及びプライバシーポリシー等（以下総称して「外部サ
          ービス利用規約等」といいます。）に同意の上、外部サービス利用規約等の定めに
          従って本サービスを利用しなければなりません。なお、利用者は、自らの責任にお
          いて外部サービスを利用するものとし、外部サービスの利用に関連して利用者に発
          生した損害について一切の責任を負いません。
        </li>
      </ul>

      <h5>第 7 条 （ネットワーク手数料）</h5>
      <p className="ms-4">
        1.
        本サービスの利用にあたり、当社が別途指定する手数料が発生します。なお、本サー
        ビスの利用に関連してイーサリアムその他のネットワーク手数料が発生する場合、利
        用者が負担するものとします。購入にあたっては、購入者がネットワーク手数料を負
        担します。
      </p>
      <p className="ms-4">
        2.
        ネットワーク手数料代自体の高騰、トランザクションの遅延やトランザクションの失
        敗について当社は一切の責任を負いません。
      </p>

      <h5>第 8 条 （サービス手数料）</h5>
      <p className="ms-4">
        購入者は、第 10 条の規定により出品された NFT
        を購入した場合、当社に対して当社が
        別途定める本サービスのサービス手数料を支払う義務を負うものとします。なお、外部サ
        ービスを利用する場合に発生する手数料は外部サービス利用規約等を確認するものとし
        ます。
      </p>

      <h5>第 9 条 （ロイヤリティ）</h5>
      <p className="ms-4">
        1. 一次流通出品者は、第 10 条の規定により NFT
        の出品を行う場合、別途当社が指定
        する範囲内においてロイヤリティ率の設定を行うことができます。
      </p>
      <p className="ms-4">
        2. 前項に定めるロイヤリティは、出品した NFT
        の再販売が行われる都度発生するもの とし、NFT
        の再販売を行う二次流通出品者が、当該ロイヤリティの支払義務を負う
        ものとします。
      </p>
      <p className="ms-4">
        3. 第 1 項に定めるロイヤリティの支払いは、当該 NFT
        の再販売が行われる都度、当 該 NFT の再販売価額に対して第 1
        項で設定されたロイヤリティ率に基づき計算さ
        れ、当社指定の方法により一次流通出品者に対して支払われるものとします。
      </p>

      <h5>第 10 条 （NFT の発行及び出品）</h5>
      <ul className="ms-4">
        <li>
          1. 利用者は、当社指定の方法により、本サービス上で NFT
          を作成し、保有すること ができます。なお、NFT 作成にかかる GAS
          代は利用者において利用者において負 担するものとします。
        </li>
        <li>
          2.
          利用者は、当社指定の方法により本サービス上で対象データを基に作成した
          NFT 及び本サービス内で購入した NFT の出品を行うことができます。
        </li>
        <li>
          3. 出品者は、前項に定める NFT
          の出品にあたり、販売価格、当社が別途指定する購
          入通貨、対象データの情報（名称、URL、説明事項等を含みますがこれらに限らな
          いものとします。）、ウォレットアドレス、ロイヤリティ率、販売方式（本サービ
          ス又は本サービス及び外部 NFT
          マーケットプレイス）への同時出品の選択を含み
          ます。）その他の当社が指定した取引情報の設定を行わなければなりません。
        </li>
        <li>
          4. 出品者は、NFT
          の出品にあたり、以下の各号に定める行為をしてはならないものと します。
          <ul className="ms-4">
            <li>
              (1) データ改ざん、不正なツールの使用その他不適切な方法で生成された
              NFT を出 品すること
            </li>
            <li>(2) 前項に定める取引情報に虚偽の情報の登録を行うこと</li>
            <li>(3) 特定の利用者のみと取引する目的で NFT を出品すること</li>
            <li>
              (4)
              本規約、法令及びガイドライン等のルールその他公序良俗に反するような方法
              で NFT の出品を行うこと。
            </li>
          </ul>
        </li>
        <li>
          5. 出品者は、本サービス上で出品を行う NFT
          が資金決済法に定める暗号資産、前払式
          支払手段、金融商品取引法に定める電子記録移転権利ではないことを保証するもの
          とします。
        </li>
        <li>
          6. 当社は、出品者が本サービス上で出品を行う NFT
          に関し、本規約及び当社が定め
          る基準を満たさないと判断した場合、予め出品者に通知することなく、当該
          NFT
          の出品を拒否することができるものとします。なお、当社による拒否に起因して出
          品者に損害が発生した場合であっても、当社は、出品者に対して損害を賠償する義
          務を一切負わないものとします。
        </li>
        <li>
          7. 出品者は、本サービスまたは本サービスを通じて本サービスおよび外部
          NFT マー ケットプレイスにおいて出品した NFT（以下「出品
          NFT」といいます。）につい
          て、その販売価格相当の暗号資産（以下「販売代金」といいます。）を、NFT
          「購入者または外部NFT マーケットプレイスにおいて出品」を購入した者から直接受領す ることに同意します。当社および外部 NFT
          マーケットプレイスは、出品者に代わ
          って販売代金を受領することはありません。
        </li>
        <li>
          8. 当社は、本サービス又は外部 NFT マーケットプレイスにおいて出品 NFT
          が購入さ れ、購入者又は外部 NFT マーケットプレイスにおいて出品 NFT
          を購入した者から
          販売代金及び当社が別途定めるサービス手数料又は販売代金及び外部 NFT
          マーケ
          ットプレイスが別途定める手数料が支払われた場合、出品者に対して、別途定める
          支払期日において、以下の暗号資産を支払うものとします。
        </li>
        <li className="ms-4">
          (1) 出品 NFT が本サービス上で購入された場合：即時 販売代金
        </li>
        <li className="ms-4">
          (2) 出品 NFT が外部 NFT マーケットプレイス上で購入された場合：即時
          販売代金から外部 NFT
          マーケットプレイスが別途定める手数料を控除した金額 に相当する数量
        </li>
      </ul>

      <h5>第 11 条 （NFT の購入）</h5>
      <p className="ms-4">
        1.
        購入者は、本サービスにおいて、当社指定の方法により、販売代金及び別途当社の
        定めるサービス手数料相当量の暗号資産（ただし、いずれも当該 NFT
        の出品に際
        し、出品者が指定した暗号資産に限ります。）を自己の所有するウォレットから移
        転させることで、出品 NFT の購入を行うことができます。
      </p>
      <p className="ms-4">
        2. 購入者は、本規約に同意の上、本サービスの内容及び NFT に設定された第
        10 条第 3２項に定める取引条件等を確認し十分に理解したうえで、NFT
        の購入を行うもの とします。
      </p>
      <p className="ms-4">
        3. 購入者は、NFT
        の購入にあたり、以下の各号に定める行為をしてはならないものと します。
      </p>
      <p className="ms-7">
        (1) 特定の利用者のみと取引する目的で NFT を購入すること
      </p>
      <p className="ms-7">(2) 自己が出品した NFT を購入すること</p>

      <h5>第 12 条 （NFT の売買契約）</h5>
      <p className="ms-4">
        1. 出品 NFT
        の売買契約は、本サービス上、出品者及び購入者との間で当社指定の手
        続きが完了した時点で成立するものとし、当社は契約の当事者とはなりません。ま
        た、本サービス又は外部サービスのシステム障害等に起因して、当社指定の手続き
        が正常に完了しなかったときは、当該売買契約は成立しない場合があるものとし、
        利用者は予めこのことに同意するものとします。
      </p>
      <p className="ms-4">
        2. 出品者は、出品 NFT の売買契約成立後、速やかに出品 NFT
        を購入者のウォレット に移転するものとします。なお、出品 NFT
        の移転にかかる GAS 代は【出品者/購 入者】の負担とします。
      </p>
      <p className="ms-4">
        3. 出品 NFT の売買契約成立後、購入者は当該 NFT
        を、以下の各号に定める範囲内で
        利用することができるものとし、出品者は購入者に対し当該利用の許諾を行うもの
        とします。
        <ul className="ms-4">
          <li>
            (1) 出品 NFT
            及び対象データに関する著作権は、出品者又は出品者がライセンスを
            受けているライセンサーに帰属し、購入者には帰属しないものとします。
          </li>
          <li>
            (2) 購入者は、出品 NFT
            に関して、取得事実の公表、作品論評、再販売、本サービ
            スを含むオンライン上で展示（著作権法第 2 条第１項 9 の 4
            に定める自動公衆
            送信を含みます。）等これらに類する行為を行うことができるものとします。
          </li>
          <li>
            (3) 購入者は、出品 NFT
            の改変、商業目的での利用、その他不適切な方法での利用
            はできないものとします。
          </li>
          <li>
            (4) 出品 NFT の再販売が行われ、出品 NFT
            を新たに第三者（以下「第三購入者」
            といいます。）が保有することとなった場合、購入者は、本項に定める利用を
            行うことができなくなるものとします。なお、第三購入者は当該 NFT
            の売買契
            約が成立した時点で本項に定める範囲内での利用を行うことが可能となるもの
            とし、以後再販売が行われる都度同様とします。
          </li>
        </ul>
      </p>
      <p className="ms-4">
        4. 当社は、出品 NFT の売買契約において、出品者から購入者に対する NFT
        及び対象
        データに関する著作権、所有権その他何らの法的な権利及び義務の移転を保証する
        ものではありません。
      </p>

      <h5>第 13 条 （アフィリエイトプログラム）</h5>
      <p className="ms-4">
        1.
        利用者は以下の各号に定める方法により、本サービスのアフィリエイトプログラム
        （以下「本アフィリエイトプログラム」といいます。）に参加することができま
        す。
        <ul className="ms-4">
          <li>
            (1)
            利用者は、本サービスの内のダッシュボードページにて固有の招待リンクを取
            得します。
          </li>
          <li>
            (2)
            利用者が、友人、家族その他の本サービスに登録をしていない者（以下「招待
            者」といいます。）に対して、当該招待リンクを共有します。
          </li>
          <li>
            (3) 招待者が当該招待リンクを使用して、本サービスのアカウント登録及び
            NFT の 出品を行い、かつ当該 NFT
            の売買契約が成立し、購入代金が支払われた場合 （外部 NFT
            マーケットプレイスでの売買契約成立及び支払いを含みます。）、
            紹介者には、当社が別途定めるボーナスが付与されます。
          </li>
        </ul>
      </p>
      <p className="ms-4">
        2.
        前項の規定にかかわらず、以下の各号に定める場合、本アフィリエイトプログラム
        に参加することはできません。
        <ul className="ms-4">
          <li>(1) 招待リンクを改変した場合</li>
          <li>(2) 当社が不正行為又は詐欺行為と判断した場合</li>
          <li>(3) 商業目的で利用する場合</li>
          <li>
            (4) Twitter、Facebook
            その他の不特定多数がアクセス可能である場所で当該招待リ
            ンクを公開又は配布をした場合
          </li>
          <li>(5) その他、当社が不適当と認める場合</li>
        </ul>
      </p>

      <h5>第 14 条 （利用者情報及び通信機器に関する管理）</h5>
      <p className="ms-4">
        1.
        利用者は、利用者情報及び通信機器の管理責任を負うものとし、当社は一切の責任
        を負わないものとします。
      </p>
      <p className="ms-4">
        2.
        利用者情報及び通信機器の管理不十分、使用上の過誤、第三者の使用等による損害
        の責任は利用者が負うものとし、当社は一切の責任を負わないものとします。
      </p>
      <p className="ms-4">
        3.
        利用者は、利用者情報又は通信機器を第三者に使用されるおそれのある場合は、直
        ちに当社にその旨を連絡するとともに、当社の指示がある場合はこれに従うものと
        します。
      </p>

      <h5>第 15 条 （本サービスの提供条件）</h5>
      <p className="ms-4">
        1.
        当社は、メンテナンス等のために、利用者に通知することなく、本サービスを停止
        又は変更することがあります。
      </p>
      <p className="ms-4">
        2.
        本サービスの提供を受けるために必要な機器、通信手段等は、利用者の費用と責任
        で備えるものとします。
      </p>

      <h5>第 16 条 （知的財産権等）</h5>
      <p className="ms-4">
        1.
        利用者は、方法又は形態の如何を問わず、本サービスにおいて当社が提供する全て
        の情報及びコンテンツ（以下総称して「当社コンテンツ」といいます。）を著作権
        法に定める、私的使用の範囲を超えて複製したり、利用したりすることはできない
        ものとします。
      </p>
      <p className="ms-4">
        2.
        当社コンテンツに関する著作権、特許権、実用新案権、商標権、意匠権その他一切
        の知的財産権及びこれらの権利の登録を受ける権利（以下総称して「知的財産権」
        といいます。）は、当社又は当社がライセンスを受けているライセンサーに帰属
        し、利用者には帰属しないものとします。また、利用者は、知的財産権の存否にか
        かわらず、当社コンテンツについて、複製、配布、転載、転送、公衆送信、改変、
        翻案その他の二次利用等を行ってはならないものとします。
      </p>
      <p className="ms-4">
        3.
        利用者が本条の規定に違反することによって問題が発生した場合、利用者は、自己
        の費用と責任において当該問題を解決するとともに、当社に何らの不利益、負担又
        は損害を与えないよう適切な措置を講じなければならないものとします。
      </p>
      <p className="ms-4">
        4. 利用者が本サービス上において NFT
        に関する情報の掲載（説明事項等の取引情報
        を含みますが、これに限らないものとします。）を行った場合、著作物性の有無を
        問わず、掲載内容の一部又は全部に関し、発生しうる全ての著作権（著作権法第
        27 条及び第 28
        条に定める権利を含みます。）を無償で当社に譲渡することに同意す
        るものとします。また、利用者は、利用者が投稿した掲載内容について、目的を問
        わず、無制限に利用できる権利が当社に帰属することについて同意するものとします。
      </p>
      <p className="ms-4">
        5.
        利用者は、著作物となりうる掲載内容の一部について、当社並びに当社より正当に
        権利を取得した第三者及び当該第三者から権利を承継した者に対し、著作者人格権
        （公表権、氏名表示権及び同一性保持権を含みます。）を行使しないものとしま
        す。
      </p>

      <h5>第 17 条 （禁止事項）</h5>
      <p className="ms-4">
        1.
        当社は、利用者による本サービスの利用に際して、以下の各号に定める行為を禁止
        します。
        <ul className="ms-4">
          <li>(1) 本規約に違反する行為</li>
          <li>
            (2)
            当社、当社がライセンスを受けているライセンサーその他第三者の知的財産
            権、特許権、実用新案権、意匠権、商標権、著作権、肖像権等の財産的又は人
            格的な権利を侵害する行為又はこれらを侵害する恐れのある行為
          </li>
          <li>
            (3)
            当社又は第三者に不利益若しくは損害を与える行為又はその恐れのある行為
          </li>
          <li>
            (4) 不当に他人の名誉や権利、信用を傷つける行為又はその恐れのある行為
          </li>
          <li>(5) 法令又は条例等に違反する行為</li>
          <li>
            (6)
            公序良俗に反する行為若しくはその恐れのある行為又は公序良俗に反する恐れ
            のある情報を他の利用者又は第三者に提供する行為
          </li>
          <li>
            (7)
            犯罪行為、犯罪行為に結びつく行為若しくはこれを助長する行為又はその恐れ
            のある行為
          </li>
          <li>
            (8) 事実に反する情報又は事実に反する恐れのある情報を提供する行為
          </li>
          <li>
            (9)
            当社のシステムへの不正アクセス、それに伴うプログラムコードの改ざん、位
            置情報を故意に虚偽、通信機器の仕様その他アプリケーションを利用してのチ
            ート行為、コンピューターウィルスの頒布その他本サービスの正常な運営を妨
            げる行為又はその恐れのある行為
          </li>
          <li>(10)マクロ及び操作を自動化する機能やツール等を使用する行為</li>
          <li>(11)本サービスの信用を損なう行為又はその恐れのある行為</li>
          <li>
            (12)青少年の心身及びその健全な育成に悪影響を及ぼす恐れのある行為
          </li>
          <li>
            (13)他の利用者のアカウントの使用その他の方法により、第三者になりすまして本
            サービスを利用する行為
          </li>
          <li>
            (14)政治、宗教などの個人思想の相違が伴う書き込み又はそれに関連する行為
          </li>
          <li>
            (15)法人、個人問わず本サービスにて当社が許可しているもの以外の営業行為
          </li>
          <li>
            (16)わいせつ、児童ポルノ又は児童虐待に相当する情報の書き込み又はそれに関連
            する行為
          </li>
          <li>
            (17)詐欺、預貯金口座及び携帯電話の違法な売買等の犯罪に結びつく又は結びつく
            恐れのある行為
          </li>
          <li>
            (18)違法な賭博・ギャンブルを行わせる又は違法な賭博・ギャンブルへの参加を勧
            誘する行為
          </li>
          <li>
            (19)マネーロンダリング、犯罪収益に関する行為、テロ資金供与に関する行為又は
            その疑いがある行為
          </li>
          <li>(20)当社が発行した NFT に係る知的財産権を侵害する行為</li>
          <li>(21)その他当社が不適当と判断する行為</li>
        </ul>
      </p>
      <p className="ms-4">
        2.
        前項の禁止行為に該当するか否かの判断は、当社の裁量により行うものとし、当社
        は判断基準について説明責任を負わないものとします。
      </p>
      <p className="ms-4">
        3.
        当社は、利用者の行為が、第１項各号のいずれかに該当すると判断した場合、事前
        に通知することなく、以下の各号のいずれか又は全ての措置を講じることができる
        ものとします。{" "}
        <ul className="ms-4">
          <li>(1) 本サービスの利用制限</li>
          <li>(2) 本契約の解除による退会処分</li>
          <li>(3) その他当社が必要と判断する行為</li>
        </ul>
      </p>
      <p className="ms-4">
        4.
        前項の措置により利用者に生じた損害について、当社は一切の責任を負わないもの
        とします。
      </p>

      <h5>第 18 条 （解除）</h5>
      <p className="ms-4">
        1.
        当社は、利用者が以下の各号のいずれかに該当した場合、何らの通知等を要するこ
        となく、本契約を解除し、退会させることができるものとします。
        <ul className="ms-4">
          <li>(1) 利用者情報に虚偽の情報が含まれている場合</li>
          <li>(2) 過去に当社から退会処分を受けていた場合</li>
          <li>
            (3)
            利用者の相続人等から利用者が死亡した旨の連絡があった場合又は当社が利用
            者の死亡の事実を確認できた場合
          </li>
          <li>(4) 未成年が法定代理人の同意なく、本サービスを利用した場合</li>
          <li>
            (5)
            成年被後見人、被保佐人又は被補助人が、成年後見人、保佐人又は補助人等の
            同意なく、本サービスを利用した場合
          </li>
          <li>
            (6)
            反社会的勢力等（暴力団、暴力団構成員、暴力団構成員でなくなった時から
            5
            年を経過しない者、準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴ
            ロ、政治活動標ぼうゴロ、特殊知能暴力集団等又はこれらに準ずる者）に該当
            すると当社が判断した場合
          </li>
          <li>(7) 当社からの要請に対し誠実に対応しない場合</li>
          <li>(8) その他当社が不適当と判断した場合</li>
        </ul>
      </p>
      <p className="ms-4">
        2. 前項各号に掲げる場合のほか、当社は、利用者に対して 30
        日前までに事前に通知
        することにより、いつでも本契約を解除し、本サービスから退会させることができ
        るものとします。また、利用者が退会を希望する場合、当社が定める退会手続によ
        り、当月末日をもって本契約を解除し、退会することができます。
      </p>
      <p className="ms-4">
        3.
        前三項の措置により利用者に生じた損害について、当社は一切の責任を負わないも
        のとします。
      </p>
      <p className="ms-4">
        4. 第 1 項及び第 2
        項の措置により本契約を解除され又は解除した利用者は、本契約の
        解除時に期限の利益を喪失し、直ちに、当社に対し負担する全ての債務を履行する
        ものとします。
      </p>

      <h5>第 19 条 （秘密保持）</h5>
      <p className="ms-4">
        1.
        利用者は、本サービスの提供に関して、当社から開示された秘密情報を第三者に開
        示又は漏洩してはなりません。なお、秘密情報とは、文書、電磁的データ、口頭そ
        の他形式の如何を問わず、又は秘密の表示若しくは明示又はその範囲の特定の有無
        にかかわらず、本サービス導入に関して開示された当社の技術上、営業上又は経営
        上の情報をいいます。
      </p>
      <p className="ms-4">
        2. 次の各号の情報は、秘密情報に該当しないものとします。
        <ul className="ms-4">
          <li>(1) 開示を受けた時、既に所有していた情報</li>
          <li>
            (2)
            開示を受けた時、既に公知であった情報又はその後自己の責に帰さない事由に
            より公知となった情報
          </li>
          <li>(3) 開示を受けた後に、第三者から合法的に取得した情報</li>
          <li>(4) 開示された秘密情報によらず独自に開発し又は創作した情報</li>
          <li>(5) 法令の定め又は裁判所の命令に基づき開示を要請された情報</li>
        </ul>
      </p>
      <p className="ms-4">
        3.
        利用者は、本サービスの終了、本契約の解約その他の事由により本契約が終了した
        場合、当社の指示に従い秘密情報を速やかに返還又は廃棄します。なお、廃棄にあ
        たっては、秘密情報を再利用できない方法をとるものとします。
      </p>

      <h5>第 20 条 （免責）</h5>
      <p className="ms-4">
        1.
        本サービスの内容についてその完全性、正確性及び有効性等を、当社は一切の保証
        をしないものとします。
      </p>
      <p className="ms-4">
        2.
        利用者が本サービスを利用するにあたり、本サービスから、本サービスに関わる関
        連会社又は外部サービスに遷移する場合があります。その場合、利用者は、自らの
        責任で外部サービスの利用規約等に同意の上、本サービス及び外部サービスを利用
        するものとします。なお、外部サービスの内容について、その完全性、正確性及び
        有効性等について、当社は一切の保証をしないものとします。
      </p>
      <p className="ms-4">
        3.
        利用者が利用者情報の変更を行わなかったことにより損害を被った場合でも、当社
        は一切の責任を負わないものとします。
      </p>
      <p className="ms-4">
        4.
        利用者は、法令の範囲内で本サービスをご利用ください。本サービスの利用に関連
        して利用者が日本又は外国の法令に触れた場合でも、当社は一切の責任を負わない
        ものとします。
      </p>
      <p className="ms-4">
        5.
        当社は、本サービスに中断、中止その他の障害が生じないことを保証しません。ま
        た、当社は、以下の各号に定める場合、利用者に通知することなく、本サービスを
        停止又は変更することがありますが、この場合においても当社は一切の責任を負わ
        ず、利用者は異議を述べないものとします
        <ul className="ms-4">
          <li>
            (1)
            当社が本サービスのメンテナンス若しくはセキュリティ対策又はバージョン管
            理若しくはアップデート等を行う場合
          </li>
          <li>
            (2)
            本サービスの提供に必要なシステムへの不正アクセスにより、当社による本サー
            ビスの安全な提供が困難であると当社が合理的に判断した場合
          </li>
          <li>
            (3)
            地震・津波・台風・豪雨・豪雪その他の天災地変、戦争、テロ、内乱、暴動、感
            染症等の公衆衛生に関する緊急事態、政府又は政府機関の行為、労働争議、停電、
            電気通信の中断・中止、電気通信設備の故障・不具合、輸送機関の事故その他の
            不可抗力により、本サービスの提供が困難になったと当社が合理的に判断した場
            合
          </li>
        </ul>
      </p>
      <p className="ms-4">
        6.
        本サービスの提供を受けるために必要な機器、通信手段等の環境は全て利用者の費
        用と責任で備えるものとします。また、本サービスの利用にあたり必要となる通信
        費用は、全て利用者の負担とします。
      </p>
      <p className="ms-4">
        7.
        予期しない不正アクセス等の行為によって利用者情報を盗取された場合でも、それ
        によって生じる利用者の損害等に対して、当社は一切の責任を負わないものとしま
        す。
      </p>
      <p className="ms-4">
        8.
        当社は、天災、地変、火災、ストライキ、通商停止、戦争、内乱、感染症の流行そ
        の他の不可抗力により本契約の全部又は一部に不履行が発生した場合、一切の責任
        を負わないものとします。
      </p>
      <p className="ms-4">
        9. 当社は、第 7
        条に定めるネットワーク手数料の高騰その他本ブロックチェーン及び
        外部サービスにおけるトランザクションの遅延及び失敗について一切の責任を負わ
        ないものとします。
      </p>
      <p className="ms-4">
        10.
        当社は、前各項に定めるほか、当社に故意又は重過失がない限り、利用者が被った
        損害について一切の責任を負わないものとします。
      </p>
      <p className="ms-4">
        11.
        本サービスの利用に関し、利用者が他の利用者との間でトラブル（本サービス内外
        を問いません。）になった場合でも、当社は一切の責任を負わないものとし、利用
        者間のトラブルは、当該利用者が自らの費用と負担において解決するものとしま
        す。
      </p>

      <h5>第 21 条 （損害賠償責任）</h5>
      <p className="ms-4">
        1.
        利用者は、本規約の違反又は本サービスの利用に関連して当社に損害を与えた場合、
        当社に発生した損害（逸失利益及び弁護士費用を含みます。）を賠償します。
      </p>
      <p className="ms-4">
        2.
        次項を除く本規約の他の定めにかかわらず、当社は、当社の帰責事由により利用者に
        損害を与えた場合、次の各号に定める範囲でのみその損害を賠償する責任を負います。
        <ul className="ms-4">
          <li>(1) 当社の故意又は重過失による場合：当該損害の全額</li>
          <li>
            (2)
            当社の軽過失による場合：現実かつ直接に発生した通常の損害（特別損害、逸失
            利益、間接損害及び弁護士費用を除く。）の範囲内とし、かつ１万円を上限とす
            る
          </li>
        </ul>
      </p>
      <p className="ms-4">
        3.
        前項にかかわらず、利用者が法人である場合又は個人が事業として若しくは事業の
        ために本サービスを利用する場合には、当社に故意又は重過失のない限り、本サー
        ビスに関連して当該利用者が被った損害につき当社は一切の責任を負いません。な
        お、当社が損害を賠償する場合は、損害発生日から直近１年間において、当該利用
        者のサービス利用料として受領した合計金額を上限とします。
      </p>

      <h5>第 22 条 （本サービスの廃止）</h5>
      <p className="ms-4">
        1.
        当社は、当社の都合によりいつでも本サービスの提供を廃止できるものとします。
      </p>
      <p className="ms-4">
        2.
        前項の場合、当社に故意又は重過失がある場合を除き、当社は一切の責任を負わな
        いものとします。
      </p>

      <h5>第 23 条 （お問い合わせ対応）</h5>
      <p className="ms-4">
        1.
        当社は、本サービスに関する利用者からのお問い合わせに対して回答するよう努め
        ますが、法令又は本規約上、当社に義務又は責任が発生する場合を除き、回答の義
        務を負わないものとします。
      </p>
      <p className="ms-4">
        2.
        当社は、利用者からのお問い合わせに回答するか否かの基準を開示する義務を負わ
        ないものとします。
      </p>

      <h5>第 24 条 （存続条項）</h5>
      <p className="ms-4">
        本契約の有効期間は、本契約成立時から利用者が本サービスを退会するまでの間としま
        す。なお、本契約の終了後も、第 6 条第 4 項から第 7 項、第 7 条第 2
        項、第 8 条、第 10 条第 6 条、第 12 条第 4 項、第 14 条、第 16 条、第 17
        条第 4 項、第 18 条第 3 項及び 第 4 項、第 20 条から第 30
        条の各規定は有効に存続するものとし、第 19 条の規定は本 契約の終了後 5
        年間有効に存続するものとします。
      </p>

      <h5>第 25 条 （分離可能性）</h5>
      <p className="ms-4">
        1.
        本規約の規定の一部が法令に基づいて無効と判断されても、本規約の他の規定は有
        効とします。
      </p>
      <p className="ms-4">
        2.
        本規約の規定の一部がある利用者との関係で無効又は取消となった場合でも、本規
        約は他の利用者との関係では有効とします。
      </p>

      <h5>第 26 条 （違反行為への対処方法）</h5>
      <p className="ms-4">
        1.
        利用者は、本規約に違反する行為を発見した場合は、当社にご連絡ください。
      </p>
      <p className="ms-4">
        2.
        利用者は、本規約に違反する行為への当社の対処について、異議を申し立てること
        はできないものとします。
      </p>

      <h5>第 27 条 （地位の譲渡等）</h5>
      <p className="ms-4">
        利用者は、当社の書面又は電磁的方法による事前の承諾なく、本契約上の地位又は本規
        約に基づく権利若しくは義務の全部又は一部につき、第三者に対し、譲渡、移転、担保
        設定、その他の処分をすることはできません。
      </p>

      <h5>第 28 条 （準拠法）</h5>
      <p className="ms-4">
        本規約に関する準拠法は、全て日本国の法令が適用されるものとします。
      </p>

      <h5>第 29 条 （合意管轄）</h5>
      <p className="ms-4">
        利用者と当社との間における一切の訴訟は、訴額に応じて、東京地方裁判所又は東京簡
        易裁判所を第一審の専属的合意管轄裁判所とします。
      </p>

      <h5>第 30 条 （その他）</h5>
      <p className="ms-4">
        1.
        利用者は、本規約に定めのない事項について、当社が細目等を別途定めた場合、こ
        れに従うものとします。この場合、当該細目等は、本規約と一体をなすものとしま
        す。
      </p>
      <p className="ms-4">
        2. 細目等は、当社指定の箇所に掲載した時点より効力を生ずるものとします。
      </p>
      <p className="ms-4">
        3.
        細目等と本規約の内容に矛盾抵触がある場合、本規約が優先するものとします。
      </p>
      <p>附則</p>
      <p>2023 年 6 月 1 日制定・施行</p>
    </div>
  );
}
