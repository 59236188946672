import React,{ useEffect } from "react";

export default function OurTeam() {

  useEffect(() => {
    // Scroll to the top of the page when this component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex justify-center p-3">
      <p>
        <p>特定商取引法に基づく表記</p>
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  販売業社
                </th>
                <th scope="col" class="px-6 py-3">
                  アクア株式会社
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  販売責任者
                </th>
                <td class="px-6 py-4">⿑藤 治孝</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  所在地
                </th>
                <td class="px-6 py-4">
                  〒500-8833 岐⾩市神⽥町６丁⽬１９番地アクアモバイル本店
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  メールアドレス
                </th>
                <td class="px-6 py-4">info@aqua.sh</td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  電話番号
                </th>
                <td class="px-6 py-4">
                  050-6861-1823
                  ※お問合せについては個⼈情報保護などのため本サービス内の「お問合せ」よりご連絡を頂き対応させて頂いておりま
                  す。
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  販売代⾦
                </th>
                <td class="px-6 py-4">
                  NFTごとに該当ページにて表⽰する価格、オークション形式の場合は、⼊札された⾦額での落札確定後決済となります。
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  商品代⾦以外の必要⾦額
                </th>
                <td class="px-6 py-4">
                  ・消費税および地⽅消費税
                  ・インターネット接続の場合は通信料、携帯電話からのご利⽤の場合は携帯通信料またはパケット通信料
                  ・仮想通貨の場合、Gas代（送⾦⼿数料）・銀⾏振込の場合、振込⼿数料
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  お⽀払い⽅法
                </th>
                <td class="px-6 py-4">
                  クレジットカード、銀⾏振込、仮想通貨。詳細は本サービスサイト及び利⽤規約にてご確認ください。
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  商品の引き渡し時期
                </th>
                <td class="px-6 py-4">お⽀払い決済の完了時</td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  キャンセル
                </th>
                <td class="px-6 py-4">
                  商品の特性上、キャンセルは⼀切お受けできません。
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  保証
                </th>
                <td class="px-6 py-4">
                  当社は販売した商品の不備または不具合等について、⼀切保証しません。
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  返品・交換について
                </th>
                <td class="px-6 py-4">
                  商品の特性上、返品・交換は⼀切お受けできません
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  サービスの推奨環境
                </th>
                <td class="px-6 py-4">
                  【PC】OS：Windows 10、Mac OS X 10.11以降、ブラウザ：Google
                  Chrome最新版、Safari最新版 【スマートフォン】：OS：iOS
                  13.0以降、Android 8.0以降、ブラウザ：Google Chrome最新版
                  、Safari最新版
                </td>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  その他の事項
                </th>
                <td class="px-6 py-4">
                  お客様が未成年の場合は、事前に親権者の同意を得てご利⽤ください。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </p>
    </div>
  );
}
